
import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import "./style/tailwindcss.css";
// import * as API from '@/api';
import { Table,TableColumn,Message,InputNumber,DatePicker, Upload, Rate, Pagination,Icon,Input, Dialog, Button, Transfer } from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import '@/validate/validate'
import mapBoxGl from 'mapbox-gl'
import * as turf from '@turf/turf'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueI18n from 'vue-i18n'
import zh from '@/language/zh'
import en from '@/language/en'
 
NProgress.configure({
  minimum: 0.1,
  template: `
    <div class="bar" role="bar">
      <div class="peg"></div>
    </div>
    <div class="spinner" role="spinner">
      <div class="spinner-icon"></div>
    </div>
  `,
  ease: 'ease',
  speed: 200,
})

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.prototype.$turf = turf
Vue.prototype.$mapboxgl = mapBoxGl
Vue.config.productionTip = false;
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(InputNumber)
Vue.use(DatePicker)
Vue.use(Pagination);
Vue.use(Input);
Vue.use(Upload);
Vue.use(Rate);
Vue.use(Icon);
Vue.use(Dialog).use(Button).use(Transfer);
Vue.prototype.$message = Message;
Vue.use(VueI18n)

const messages = {
  zh,
  en
};

const i18n = new VueI18n({
  messages,
  locale: 'zh'
})

locale.use(lang)
new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    // Vue.prototype.$API = API;
  },
  render: (h) => h(App),
}).$mount("#app");
